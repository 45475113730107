import {
    Box, Breadcrumbs,
    Container, Hidden, Link, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 'calc(100vh - 283px)',
        '@media (max-width: 959px)': {
            minHeight: 'calc(100vh - 420px)',
        },
    },
    breadCrumbLink: {
        color: '#727272',
    },
    breadCrumbPageTitle: {
        color: '#727272',
        fontWeight: 700,
    },

}));

export default ({ children, breadcrumb }) => {
    const classes = useStyles();
    let breadcrumbItems = [];
    if (Array.isArray(breadcrumb)) breadcrumbItems = [...breadcrumb];
    else breadcrumbItems.push({ name: breadcrumb });

    return (
        <Container maxWidth="xl" className={classes.root}>
            <Box my={13}>
                <Hidden xsUp>
                    <Breadcrumbs separator=">" aria-label="breadcrumb">
                        <Link color="inherit" href="/">
                            <Typography className={classes.breadCrumbLink} key="Home" variant="subtitle2">Home</Typography>
                        </Link>
                        {breadcrumbItems.map((item) => {
                            if (item.path) {
                                return (
                                    <Link key={item.name} href={item.path}>
                                        <Typography className={classes.breadCrumbLink} key={item.name} variant="subtitle2">{item.name}</Typography>
                                    </Link>
                                );
                            }
                            return (<Typography className={classes.breadCrumbPageTitle} key={item.name} variant="subtitle2">{item.name}</Typography>);
                        })}
                    </Breadcrumbs>
                </Hidden>
                <Box mt={3}>
                    {children}
                </Box>
            </Box>
        </Container>

    );
};
