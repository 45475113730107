import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Typography,
    Backdrop,
    Button,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import lottie from 'lottie-web';

import API from '../../../../api/API';

import { UserContext } from '../../../../contexts';
import userConstants from '../../../../contexts/User/userConstants';
import { ProductsContext } from '../../../../contexts/Products/context';

import GDTheme from '../../../../theme/GDTheme';

import SelectPaymentSubStep from './payment-substeps/SelectPaymentSubStep';
import BankTransferPaymentSubStep from './payment-substeps/BankTransferPaymentSubStep';
import CardsPaymentSubStep from './payment-substeps/CardsPaymentSubStep';

import { ErrorCodes } from '../../../../helpers/errorCodes';
import { sendAddPaymentInfoGAEvent, sendPurchaseFailureGAEvent, sendPurchaseGAEvent } from '../../../../helpers/gaHelper';
import cartHelper from '../../../../helpers/cartHelper';
import handler from '../../../../helpers/handler';
import paymentHelper from '../../../../helpers/paymentHelper';

import approvedPaymentAnimation from '../../../../assets/approvedPayment.json';
import warningPaymentAnimation from '../../../../assets/warningPayment.json';
import errorPaymentAnimation from '../../../../assets/errorPayment.json';
import loadingPaymentAnimation from '../../../../assets/loadingPayment.json';

import protectedPurchase from '../../../../data/images/protected-purchase.png';
import { isSubsidizedShippingPrice } from '../../../../data/constants';

const BACKGROUND_PAYMENT_GREEN = 'rgba(67, 160, 71, 0.8)';
const BACKGROUND_PAYMENT_ORANGE = 'rgba(254, 120, 23, 0.8)';
const BACKGROUND_PAYMENT_RED = 'rgba(176, 0, 32, 0.8)';
const BACKGROUND_PAYMENT_BLACK = 'rgba(0, 0, 0, 0.76)';

const CLOSED_CIRCLE_ANIMATION_STATE = {
    backgroundColor: BACKGROUND_PAYMENT_BLACK,
    opacity: 0.8,
};
const BASE_OPEN_CIRCLE_ANIMATION_STATE = {
    width: '400vh',
    height: '400vh',
    opacity: 0.8,
};
const SUCCESS_OPEN_CIRCLE_ANIMATION_STATE = {
    ...BASE_OPEN_CIRCLE_ANIMATION_STATE,
    backgroundColor: BACKGROUND_PAYMENT_GREEN,
};
const WARNING_OPEN_CIRCLE_ANIMATION_STATE = {
    ...BASE_OPEN_CIRCLE_ANIMATION_STATE,
    backgroundColor: BACKGROUND_PAYMENT_ORANGE,
};
const ERROR_OPEN_CIRCLE_ANIMATION_STATE = {
    ...BASE_OPEN_CIRCLE_ANIMATION_STATE,
    backgroundColor: BACKGROUND_PAYMENT_RED,
};

const EASE_IN_TEXT_TRANSITION = {
    transition: 'opacity 1s ease-in',
    opacity: 1,
};
const EASE_OUT_TEXT_TRANSITION = {
    transition: 'opacity 1s ease-out',
    opacity: 0,
};

const LOADING_ANIMATION_DURATION = 1835;
const SECS_AFTER_LOADING_TO_ALLOW_CIRCLE_ANIMATION = 1570;

const YELLOW_TEXT_COLOR = '#F8E837';
const WHITE_TEXT_COLOR = '#F8F9FB';

const getSecsForLoadingAnimationFinish = (requestStart) => {
    const requestTime = new Date() - requestStart;
    const timesShouldLoadingAnimationLoop = Math.ceil(requestTime / LOADING_ANIMATION_DURATION);

    let secs = (LOADING_ANIMATION_DURATION * timesShouldLoadingAnimationLoop) - requestTime;
    // Se agrega un spin mas para que no sea un corte brusco
    secs += LOADING_ANIMATION_DURATION;

    return secs;
};

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 4000,
    },
    backdropContentWrapper: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        textAlign: 'center',
        height: '369px',
        zIndex: 4005,
        '& button': {
            opacity: '0',
            visibility: 'hidden',
            animation: '$buttonAppears 2.5s normal forwards ease-in-out',
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        },
    },
    paymentSubtitle: {
        maxWidth: 480,
        fontWeight: 400,
        marginTop: 8,
    },
    protectedPurchase: {
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            width: 100,
        },
        objectFit: 'contain',
    },
    subContainer: {
        marginTop: '16px',
        marginBottom: '24px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '8px',
        },
    },
    // eslint-disable-next-line mui-unused-classes/unused-classes
    '@keyframes buttonAppears': {
        from: {
            opacity: '0',
            visibility: 'hidden',
        },
        '90%': {
            opacity: '0',
            visibility: 'hidden',
        },
        to: {
            opacity: '1',
            visibility: 'visible',
        },
    },
    backdropContentIconContainer: {
        '@media (min-width: 1920px)': {
            width: '400px',
        },
        width: '200px',
        height: '207px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    backdropContentIcon: {
        transform: 'scale(1.25)',
    },
    backdropContentTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
    },
    circle: {
        position: 'absolute',
        top: '46.5%', // Alinea la altura de nacimiento del circulo, con la ubicacion de la animacion
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '50%',
        width: 0,
        height: 0,
        opacity: 0,
        transition: 'all 1s',
        zIndex: 3999,
    },
    continueButton: {
        width: 129,
        height: 34,
        margin: GDTheme().spacing(2),
        marginTop: 0,
    },
}));

const backdropModes = {
    normal: {
        text: (selectedPaymentMode) => (
            selectedPaymentMode === paymentHelper.paymentModeConstants.BANK_TRANSFER
                ? 'Estamos procesando tu orden'
                : 'Estamos procesando tu pago'
        ),
        subText: 'Por favor aguarda unos instantes',
        animation: loadingPaymentAnimation, // 1.18s dura el spin de carga.
        background: BACKGROUND_PAYMENT_BLACK,
        shouldLoop: true,
    },
    approvedPayment: {
        text: (selectedPaymentMode) => (
            selectedPaymentMode === paymentHelper.paymentModeConstants.BANK_TRANSFER
                ? 'Orden creada'
                : 'Pago aprobado'
        ),
        subText: '',
        animation: approvedPaymentAnimation,
        background: BACKGROUND_PAYMENT_GREEN,
        circleAnimationState: SUCCESS_OPEN_CIRCLE_ANIMATION_STATE,
        shouldLoop: false,
        hasButton: true,
    },
    warningPayment: {
        text: () => ('Ha ocurrido un error'),
        subText: '',
        animation: warningPaymentAnimation,
        background: BACKGROUND_PAYMENT_ORANGE,
        circleAnimationState: WARNING_OPEN_CIRCLE_ANIMATION_STATE,
        shouldLoop: false,
        hasButton: true,
    },
    rejectedPayment: {
        text: () => ('Pago rechazado'),
        subText: '',
        animation: errorPaymentAnimation,
        background: BACKGROUND_PAYMENT_RED,
        circleAnimationState: ERROR_OPEN_CIRCLE_ANIMATION_STATE,
        shouldLoop: false,
        hasButton: true,
    },
};

const Payment = ({
    buyerData,
    goToNextStep,
    goToPreviousStep,
    loading,
    selectedPaymentMode,
    setOrder,
    setOutOfStockBuilds,
    setOutOfStockProducts,
    setProBuildOutOfStockModal,
    setProductsOutOfStockModal,
    setSelectedPaymentMode,
    shippingData,
}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const shippingCost = shippingData.price;
    const [state, dispatch] = useContext(UserContext);
    const { productsDispatch } = useContext(ProductsContext);
    const [actualSubstep, setActualSubstep] = useState(0);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [backdropMode, setBackdropMode] = useState('normal');
    const [allowBackdropClick, setAllowBackdropClick] = useState(false);
    const [circleAnimationState, setCircleAnimationState] = useState(CLOSED_CIRCLE_ANIMATION_STATE);
    const [backdropText, setBackdropText] = useState(
        backdropModes.normal.text(selectedPaymentMode),
    );
    const [backdropTextColor, setBackdropTextColor] = useState(YELLOW_TEXT_COLOR);
    const [backdropSubText, setBackdropSubText] = useState(backdropModes.normal.subText);
    const [backdropTextTransition, setBackdropTextTransition] = useState(null);

    useEffect(() => {
        if (selectedPaymentMode) setBackdropText(backdropModes.normal.text(selectedPaymentMode));
    }, [selectedPaymentMode]);

    useEffect(() => {
        const svgContainer = document.getElementById('svgContainer');
        lottie.destroy();
        lottie.loadAnimation({
            loop: backdropModes[backdropMode].shouldLoop,
            wrapper: svgContainer,
            renderer: 'svg',
            animationData: backdropModes[backdropMode].animation,
        });
    }, [backdropMode]);

    const handleCircleExpansion = (mode) => {
        setBackdropTextColor(WHITE_TEXT_COLOR);
        setBackdropTextTransition(EASE_IN_TEXT_TRANSITION);
        setBackdropText(backdropModes[mode].text(selectedPaymentMode));
        setBackdropSubText('');
        setCircleAnimationState(backdropModes[mode].circleAnimationState);
    };

    const handleBackdropEffect = (mode, requestStart) => {
        const secsForLoadingAnimationFinish = getSecsForLoadingAnimationFinish(requestStart);
        const secsToAllowCircleAnimation = (
            secsForLoadingAnimationFinish + SECS_AFTER_LOADING_TO_ALLOW_CIRCLE_ANIMATION
        );
        const secsToAllowBackdropClick = secsToAllowCircleAnimation + 1500;
        setTimeout(() => {
            // ESTE TIMEOUT SIRVE PARA QUE PUEDA TERMINAR LA ANIMACION COMPLETA DE LOAD
            // ASI COINCIDE CON EL INICIO DE LA ANIMACION DE APROBADO/WARNING/RECHAZO
            setBackdropMode(mode);
        }, secsForLoadingAnimationFinish);
        setTimeout(() => {
            // ESTE TIMEOUT SIRVE PARA QUE EL TEXTO EMPIEZE A DESAPARECER
            // EN EL MOMENTO EN QUE EL CIRCULO ESTA POR EXPANDIRSE
            setBackdropTextTransition(EASE_OUT_TEXT_TRANSITION);
        }, secsToAllowCircleAnimation - 1000);
        setTimeout(() => {
            // ESTE TIMEOUT SIRVE PARA QUE EL CIRCULO EMPIEZE A EXPANDIRSE
            // EN EL MOMENTO EN QUE EL ICONO DE ESTADO LLEGA AL MAXIMO
            handleCircleExpansion(mode);
        }, secsToAllowCircleAnimation);
        setTimeout(() => {
            setAllowBackdropClick(true);
        }, secsToAllowBackdropClick);
    };

    const handleApprovedPaymentClick = () => {
        setOpenBackdrop(false);
        dispatch({ type: userConstants.DELETE_CART });
        productsDispatch({ type: 'DELETE_BUILD' });
        goToNextStep();
    };

    const setBackdropTextStatesToDefault = () => {
        setBackdropText(backdropModes.normal.text(selectedPaymentMode));
        setBackdropSubText(backdropModes.normal.subText);
        setBackdropTextColor(YELLOW_TEXT_COLOR);
        setBackdropTextTransition(null);
    };

    const handleBackdropClick = () => {
        if (allowBackdropClick === true) {
            setCircleAnimationState(CLOSED_CIRCLE_ANIMATION_STATE);
            setAllowBackdropClick(false);

            if (backdropMode === 'approvedPayment') handleApprovedPaymentClick();
            else setOpenBackdrop(false);

            setTimeout(() => {
                // TIMEOUT PARA QUE NO SEA BRUSCO EL CAMBIO DE TEXTO AL CLICKEAR BACKDROP
                setBackdropTextStatesToDefault();
            }, 500);
        }
    };

    const generateOrder = async (paymentData) => {
        setBackdropMode('normal');
        setCircleAnimationState(CLOSED_CIRCLE_ANIMATION_STATE);
        setOpenBackdrop(true);

        const usingSpecialPrice = selectedPaymentMode !== paymentHelper.paymentModeConstants.CARD;

        const requestStart = new Date();

        await API.orders.post({
            buyerData,
            cartId: state.user?.cart?.id,
            paymentData,
            shippingData,
        }).then((response) => {
            setOrder(response.data);

            sendPurchaseGAEvent({
                orderId: response.data.id,
                value: paymentData.amount,
                items: cartHelper.formatCartItemsForGAEvent(
                    state.user?.cart?.items, usingSpecialPrice,
                ),
            });
            handleBackdropEffect('approvedPayment', requestStart);
        }).catch(async (error) => {
            sendPurchaseFailureGAEvent({
                value: paymentData.amount,
                items: cartHelper.formatCartItemsForGAEvent(
                    state.user?.cart?.items,
                    usingSpecialPrice,
                ),
                errorCode: error.response?.data?.errorCode,
            });
            const status = error.response?.status;
            const errorCode = error.response?.data?.errorCode;

            if (status === 503) {
                handleBackdropEffect('warningPayment', requestStart);
                enqueueSnackbar(
                    'Las compras están temporalmente deshabilitadas. Por favor intentá nuevamente en unos minutos',
                    { variant: 'error' },
                );
            } else if (status === 401) {
                handler.logout(dispatch);
                enqueueSnackbar('Su sesión ha caducado. Vuelva a iniciar sesión.', { variant: 'warning' });
            } else if (status === 409) {
                handleBackdropEffect('warningPayment', requestStart);
                const data = error.response?.data;

                if (errorCode === ErrorCodes.NOT_ENOUGH_STOCK_FOR_ORDER) {
                    const products = data?.outOfStockProducts?.products;
                    const builds = data?.outOfStockProducts?.builds;

                    if (builds?.length > 0) {
                        setOutOfStockBuilds(builds);
                        setProBuildOutOfStockModal(true);
                    } else if (products?.length > 0) {
                        setOutOfStockProducts([...products]);
                        setProductsOutOfStockModal(true);
                    }
                } else if (errorCode === ErrorCodes.PRICES_IN_CART_AND_PRODUCTS_ARE_DIFFERENT) {
                    try {
                        const response = await API.carts.get(state.user.cart.id);
                        dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
                    } catch (err) {
                        handler.handleError({
                            error: err, userContextDispatch: dispatch, enqueueSnackbar,
                        });
                    }
                }
            } else if (status === 400 && errorCode === ErrorCodes.MP_PAYMENT_NOT_APPROVED) {
                handleBackdropEffect('rejectedPayment', requestStart);
            } else {
                handleBackdropEffect('warningPayment', requestStart);
            }
        });
    };

    const goToSelectPaymentSubStep = () => {
        setActualSubstep(0);
    };

    const goToConfirmPaymentSubStep = () => {
        if (selectedPaymentMode === null) enqueueSnackbar('Debes seleccionar un metodo de pago.', { variant: 'warning' });
        const useSpecialPrice = selectedPaymentMode !== paymentHelper
            .paymentModeConstants.CARD;

        const totalCartValue = cartHelper.getTotalPrice(
            state.user?.cart?.items, selectedPaymentMode,
            isSubsidizedShippingPrice ? 0 : shippingCost,
            state.user?.cart?.appliedCoupon?.discount_percentage,
        );
        sendAddPaymentInfoGAEvent({
            value: totalCartValue,
            paymentType: selectedPaymentMode,
            items: cartHelper.formatCartItemsForGAEvent(state.user?.cart?.items, useSpecialPrice),
        });
        if (selectedPaymentMode === paymentHelper.paymentModeConstants.CARD) setActualSubstep(1);
        if (selectedPaymentMode
            === paymentHelper.paymentModeConstants.DEBIT_CARD_OR_ONE_INSTALLMENT) {
            setActualSubstep(2);
        }
        if (selectedPaymentMode === paymentHelper.paymentModeConstants.BANK_TRANSFER) {
            setActualSubstep(3);
        }
    };

    const paymentSubsteps = [
        {
            title: 'Método de pago',
            subtitle: 'Seleccioná un método de pago.',
            content: (
                <SelectPaymentSubStep
                    loading={loading}
                    goToPreviousStep={goToPreviousStep}
                    goToConfirmPaymentSubStep={goToConfirmPaymentSubStep}
                    selectedPaymentMode={selectedPaymentMode}
                    setSelectedPaymentMode={setSelectedPaymentMode}
                    shippingCost={shippingCost}
                />
            ),
        },
        {
            title: 'Pago con tarjeta de Crédito',
            subtitle: 'Introducí los datos de tu tarjeta para realizar el pago.',
            content: (
                <CardsPaymentSubStep
                    goToSelectPaymentSubStep={goToSelectPaymentSubStep}
                    generateOrder={generateOrder}
                    selectedPaymentMode={selectedPaymentMode}
                    shippingCost={shippingCost}
                />
            ),
        },
        {
            title: 'Pago con tarjeta de Débito/Crédito (en 1 cuota)',
            subtitle: 'Introducí los datos de tu tarjeta para realizar el pago.',
            content: (
                <CardsPaymentSubStep
                    goToSelectPaymentSubStep={goToSelectPaymentSubStep}
                    generateOrder={generateOrder}
                    selectedPaymentMode={selectedPaymentMode}
                    shippingCost={shippingCost}
                />
            ),
        },
        {
            title: 'Pago con transferencia',
            subtitle: 'Para efectuar tu pago deberás realizar una transferencia a esta cuenta, podés hacerlo después de finalizar la compra.',
            content: (
                <BankTransferPaymentSubStep
                    goToSelectPaymentSubStep={goToSelectPaymentSubStep}
                    selectedPaymentMode={selectedPaymentMode}
                    generateOrder={generateOrder}
                    shippingCost={shippingCost}
                />
            ),
        },
    ];

    return (
        <>
            <Box className={classes.container}>
                <Box className={classes.subContainer}>
                    <Typography variant="h4">
                        {paymentSubsteps[actualSubstep].title}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.paymentSubtitle}>
                        {paymentSubsteps[actualSubstep].subtitle}
                    </Typography>
                </Box>
                <img src={protectedPurchase} alt="compra protegida" className={classes.protectedPurchase} />
            </Box>

            <Backdrop
                className={classes.backdrop}
                open={openBackdrop}
                style={{
                    color: backdropTextColor,
                    transition: backdropModes[backdropMode].transition,
                }}
            >
                <Box className={classes.circle} style={{ ...circleAnimationState }} />

                <Box className={classes.backdropContentWrapper}>
                    <Box className={classes.backdropContentIconContainer}>
                        <div id="svgContainer" className={classes.backdropContentIcon} />
                    </Box>
                    <Box mb={1} className={classes.backdropContentTextContainer}>
                        <Typography
                            variant="h4"
                            style={{
                                fontWeight: 700,
                                ...backdropTextTransition,
                            }}
                        >
                            {backdropText}
                        </Typography>
                    </Box>
                    <Typography
                        variant="subtitle1"
                        style={{
                            fontWeight: 300,
                            ...backdropTextTransition,
                        }}
                    >
                        {backdropSubText}
                    </Typography>
                    {
                        backdropModes[backdropMode]?.hasButton && (
                            <Button
                                variant="contained"
                                onClick={handleBackdropClick}
                                className={classes.continueButton}
                                style={{
                                    marginTop: '20px',
                                    ...backdropTextTransition,
                                }}
                            >
                                Continuar
                            </Button>
                        )
                    }
                </Box>
            </Backdrop>

            {paymentSubsteps[actualSubstep].content}
        </>
    );
};

export default Payment;
