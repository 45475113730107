import jsonwebtoken from 'jsonwebtoken';

function getUserCart() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.cart) {
        return user.cart;
    }
    return [];
}
function setUser(user) {
    const cart = getUserCart();
    const userAux = user;
    if (cart) {
        userAux.cart = cart;
    }
    localStorage.setItem('user', JSON.stringify(userAux));
}

function setUserRole(role) {
    localStorage.setItem('role', role);
}

function setUserRoleByToken(token) {
    const decodedToken = jsonwebtoken.decode(token);
    setUserRole(decodedToken.role);
}

function setUsername(username) {
    localStorage.setItem('username', username);
}

function deleteUserCart() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        delete user.cart;
        localStorage.setItem('user', JSON.stringify(user));
    }
}

function setUserCart(cart) {
    const user = JSON.parse(localStorage.getItem('user'));
    let userAux = {};
    if (user) {
        userAux = {
            email: user.email,
            role: user.role,
            shippingData: user.shippingData,
            profileImageURL: user.profileImageURL,
            cart,
        };
    } else {
        userAux = { cart };
    }
    localStorage.setItem('user', JSON.stringify(userAux));
}

function setLoggedIn(loggedIn) {
    localStorage.setItem('loggedIn', loggedIn);
}

function clearStorage() {
    localStorage.clear();
}

function logout() {
    const cart = getUserCart();
    if (cart) {
        const userAux = { cart };
        localStorage.setItem('user', JSON.stringify(userAux));
    } else {
        clearStorage();
    }
}

function waitGoogleLogin() {
    localStorage.setItem('redirectAfterGoogleLogin', window.location.pathname);
}

function clearWaitGoogleLogin() {
    localStorage.removeItem('redirectAfterGoogleLogin');
}

const storageHelper = {
    setUser,
    setUserRole,
    setUserRoleByToken,
    setUsername,
    getUserCart,
    setUserCart,
    deleteUserCart,
    setLoggedIn,
    waitGoogleLogin,
    clearWaitGoogleLogin,
    clearStorage,
    logout,
};

export default storageHelper;
