import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box, Divider, Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UserContext } from '../../contexts';
import paymentHelper from '../../helpers/paymentHelper';
import AppliedCouponCode from './AppliedCouponCode';

const useStyles = makeStyles(() => ({
    totalSubItemContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 0px',
    },
    couponDivider: { backgroundColor: '#DBDBD5' },
}));

const TotalWithCoupon = ({
    actualStep = 1,
    loadingTotalPrice = false,
    selectedPaymentMode = null,
    formatDisplayedPrice,
    formatDisplayedDiscountedPrice,
    formatDisplayedPriceWithDiscount,
}) => {
    const classes = useStyles();
    const [state] = useContext(UserContext);

    return (
        <Box>
            <Box className={classes.totalSubItemContainer}>
                <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Subtotal
                </Typography>
                <Box display="flex" justifyContent="flex-end">
                    {loadingTotalPrice
                        ? <CircularProgress color="#000" />
                        : (
                            <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                                {actualStep > 1
                                    ? (
                                        <>
                                            {selectedPaymentMode
                                            === paymentHelper.paymentModeConstants.CARD
                                                ? formatDisplayedPrice(
                                                    state.user?.cart?.totalPrice?.list,
                                                )
                                                : formatDisplayedPrice(
                                                    state.user?.cart?.totalPrice?.special,
                                                )}
                                        </>
                                    )
                                    : formatDisplayedPrice(
                                        (state.user?.cart?.totalPrice?.special || 0),
                                    )}
                            </Typography>
                        )}
                </Box>
            </Box>
            <Divider className={classes.couponDivider} />

            <AppliedCouponCode
                actualStep={actualStep}
                selectedPaymentMode={selectedPaymentMode}
                formatDisplayedDiscountedPrice={formatDisplayedDiscountedPrice}
            />
            <Divider className={classes.couponDivider} />

            <Box className={classes.totalSubItemContainer}>
                <Typography variant="h6">
                    Total
                </Typography>
                <Box display="flex" justifyContent="flex-end">
                    {loadingTotalPrice
                        ? <CircularProgress color="#000" />
                        : (
                            <Typography variant="h3">
                                {actualStep > 1
                                    ? (
                                        <>
                                            {selectedPaymentMode
                                            === paymentHelper.paymentModeConstants.CARD
                                                ? formatDisplayedPriceWithDiscount(
                                                    state.user?.cart?.totalPrice?.list,
                                                )
                                                : formatDisplayedPriceWithDiscount(
                                                    state.user?.cart?.totalPrice?.special,
                                                )}
                                        </>
                                    )
                                    : formatDisplayedPriceWithDiscount(
                                        (state.user?.cart?.totalPrice?.special || 0),
                                    )}
                            </Typography>
                        )}
                </Box>
            </Box>
        </Box>
    );
};

export default TotalWithCoupon;
