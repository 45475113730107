import { INVOICE_TYPES } from '../../../common/constants';

const initialBuyerData = {
    businessAddress: '',
    businessName: '',
    cuit: '',
    dni: '',
    firstName: '',
    floor: '',
    invoiceType: INVOICE_TYPES.finalConsumer,
    ivaCondition: '',
    lastName: '',
    locality: undefined,
    province: undefined,
};

const initialShippingData = {
    address: '',
    codigoPostal: null,
    dni: '',
    firstName: '',
    floor: '',
    isThirdPartyAuthorized: false,
    lastName: '',
    locality: null,
    phone: '',
    price: null,
    province: null,
    useTelegramForTracking: false,
};

export {
    initialBuyerData,
    initialShippingData,
};
