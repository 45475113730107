/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box, Typography, Button, Modal, Divider,
} from '@material-ui/core';
import { Close, HighlightOffOutlined } from '@material-ui/icons';
import helper from '../../helpers/cartHelper';
import ModalCard from '../pro-builder/components/ModalCard';
import imageHelper from '../../helpers/imageHelper';
import paymentHelper from '../../helpers/paymentHelper';

const useStyles = makeStyles((theme) => ({
    cardNameContainer: {
        lineHeight: '10px',
        overflow: 'hidden',
        width: '70%',
        height: '34px',
        textOverflow: 'ellipsis',
    },
    cardPriceContainer: {
        width: '30%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    cardTextName: {
        fontWeight: 400,
        textAlign: 'start',
        [theme.breakpoints.down('xs')]: {
            fontSize: '11px',
        },
    },
    cardTextPrice: {
        textAlign: 'end',
        fontWeight: 700,
        [theme.breakpoints.down('xs')]: {
            fontSize: '11px',
        },
    },
    modal: {
        position: 'absolute',
        width: '45%',
        height: 'auto',
        backgroundColor: '#FFF',
        padding: '20px 20px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('sm')]: {
            width: '80vw',
            height: 'auto',
        },
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 700,
        marginBottom: 20,
    },
    modalCross: {
        background: 'none',
        border: 'none',
        color: '#727272',
        padding: '0',
        margin: '0',
        minWidth: 'auto',
    },
    modalBody: {
        height: '75%',
        borderColor: '#F8E837',
        maxHeight: '60%',
    },
    cardsContainer: {
        maxHeight: '50vh',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '0.3em',
            paddingLeft: '10px',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(0,0,0,.4)',
        },
    },
    cardTitleName: {
        fontSize: 16,
    },
    cardTotalPrice: {
        fontWeight: 700,
        marginLeft: 15,
    },
    noOutlineButton: {
        border: 'none',
        outline: 'none',
        paddingLeft: 0,
    },
    deleteButtonIcon: {
        fill: '#EA4335',
    },
    divider: {
        marginRight: 8,
        '&:last-child': {
            display: 'none',
        },
    },
}));

const ModalOrderProductList = ({
    order, openModal, toggleModal, buildSpecs, productsDispatch, setActualStep, totalPrice,
}) => {
    const classes = useStyles();
    const allProducts = [
        ...(order?.builds || []),
        ...(order?.products || []),
        ...(order?.proBuilders || []),
    ];
    const getProductName = (product) => {
        if (product.item?.productShadow?.type === 'operative_system') {
            return `Sistema operativo (${product.item.productShadow?.name})`;
        }
        if (product.item?.quantity < 2) {
            return product.item?.productShadow?.name || 'Pc armada en el probuilder';
        }
        return `${product.item?.productShadow?.name || 'Pc armada en el probuilder'} (x ${product.item.quantity})`;
    };

    const viewBuildSpecs = buildSpecs
        ? Object.entries(buildSpecs).map(([key, spec]) => {
            if (spec?.images || Array.isArray(spec)) {
                return <ModalCard key={key} spec={spec} />;
            }
            return '';
        })
        : <Typography>Todavía no hay nada por aquí</Typography>;

    const orderPaymentMethod = order?.paymentData?.method;

    const body = (
        <Box className={classes.modal}>
            <Box className={classes.modalHeader}>
                <Typography variant="h6">{order ? `Detalle de la compra: ${order.code}` : 'Detalle Pro Builder'}</Typography>
                <Button onClick={toggleModal} className={classes.modalCross}>
                    <Close />
                </Button>
            </Box>
            <Box
                className={classes.modalBody}
                borderBottom={1}
            >
                <Box display="flex" justifyContent="space-between" mb={2}>
                    <Typography
                        variant="subtitle1"
                        className={classes.cardTitleName}
                    >
                        {order ? 'Producto' : 'Componente'}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        style={{ marginRight: 44 }}
                        className={classes.cardTitleName}
                    >
                        Precio unitario
                    </Typography>
                </Box>
                <Box className={classes.cardsContainer}>
                    {order
                        ? (allProducts.map((product) => (
                            <>
                                <Box width="100%" py={1.25} display="flex" justifyContent="space-between" alignItems="center">
                                    <Box mr={2}>
                                        <img
                                            src={imageHelper
                                                .getProductImageSrc(product.item.productShadow)}
                                            alt={imageHelper
                                                .getProductImageAlt(product.item.productShadow)}
                                            width={60}
                                        />
                                    </Box>
                                    <Box width="100%" display="flex" justifyContent="space-between" alignItems="flex-start">
                                        <Box className={classes.cardNameContainer}>
                                            <Typography variant="subtitle1" className={classes.cardTextName}>{getProductName(product)}</Typography>
                                        </Box>
                                        <Box
                                            className={classes.cardPriceContainer}
                                            ml={3}
                                            mr={5}
                                        >
                                            <Typography
                                                className={classes.cardTextPrice}
                                                variant="subtitle1"
                                            >
                                                {orderPaymentMethod
                                                        === paymentHelper.paymentModeConstants.CARD
                                                    ? helper
                                                        .formatPrice(product.item.price.list)
                                                    : helper
                                                        .formatPrice(product.item.price.special)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Divider className={classes.divider} />
                            </>
                        ))) : viewBuildSpecs}
                </Box>
            </Box>
            {order && order?.paymentData.shippingPrice > 0 ? (
                <>
                    <Box display="flex" justifyContent="flex-end" alignItems="center" mr={5.5} my={2}>
                        <Typography variant="subtitle1">
                            Envío:
                        </Typography>
                        <Typography variant="subtitle1" className={classes.cardTotalPrice}>
                            {helper.formatPrice(order.paymentData.shippingPrice)}
                        </Typography>
                    </Box>
                    <Divider className={classes.divider} style={{ background: '#F8E837' }} />
                </>
            ) : null}
            {order?.appliedCoupon && (
                <>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        mr={5.5}
                        mt={2}
                    >
                        <Typography variant="subtitle1">
                            Subtotal:&nbsp;
                        </Typography>
                        <Typography variant="subtitle1">
                            {helper.formatPrice(order.subTotalPrice)}
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        mr={5.5}
                        mt={2}
                    >
                        <Typography variant="subtitle1">
                            {`Descuento del cupón (${order.appliedCoupon.discount_percentage}%):`}
                        </Typography>
                        <Typography variant="subtitle1">
                            &nbsp;-
                            {helper.formatPrice(order.subTotalPrice - order.totalPrice)}
                        </Typography>
                    </Box>
                </>
            )}
            <Box display="flex" justifyContent="flex-end" alignItems="center" mr={5.5} mt={2}>
                <Typography variant="subtitle1">
                    {order ? 'Total:' : 'Total acumulado:'}
                </Typography>
                <Typography variant="subtitle1" className={classes.cardTotalPrice}>
                    {order ? helper.formatPrice(order.totalPrice) : helper.formatPrice(totalPrice)}
                </Typography>
            </Box>

            <Box display="flex" justifyContent="space-evenly" mx={11} my={2}>
                {order ? null : (
                    <Button
                        variant="outlined"
                        type="button"
                        startIcon={(
                            <HighlightOffOutlined
                                className={classes.deleteButtonIcon}
                            />
                        )}
                        onClick={() => {
                            productsDispatch({
                                type: 'DELETE_BUILD',
                            });
                            setActualStep(0);
                        }}
                        className={classes.noOutlineButton}
                    >
                        Borrar todo
                    </Button>
                )}
                <Button
                    variant="contained"
                    onClick={toggleModal}
                >
                    Cerrar
                </Button>
            </Box>
        </Box>
    );
    return (
        <>
            <Modal
                open={openModal}
                onClose={toggleModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </>
    );
};

export default ModalOrderProductList;
