const ErrorCodes = {
    PRICES_IN_CART_AND_PRODUCTS_ARE_DIFFERENT: 1,
    NOT_ENOUGH_STOCK_FOR_ORDER: 2,
    SHIPPING_COST_IS_INVALID: 3,
    MP_PAYMENT_NOT_APPROVED: 4,
};

module.exports = {
    ErrorCodes,
};
