import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box, Typography, Button,
} from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CardImage from './card_icon.svg';
import BankTransferImage from './bank-transfers_icon.svg';
import DebitCardOr1InstallmentImage from './debit-card-or-1-installment_icon.svg';
import Ahora6Icon from '../../../../product/productIcons/ahora6_icon.svg';
import GDTheme from '../../../../../theme/GDTheme';
import colors from '../../../../../theme/colors';
import paymentHelper from '../../../../../helpers/paymentHelper';
import { UserContext } from '../../../../../contexts';
import cartHelper from '../../../../../helpers/cartHelper';
import { isSubsidizedShippingPrice } from '../../../../../data/constants';

const useStyles = makeStyles((theme) => ({
    paymentModeWrapper: {
        display: 'flex',
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paymentModeSelector: {
        minHeight: '67px',
        maxHeight: '94px',
        width: '100%',
        display: 'flex',
        background: colors.grey,
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: '14px 18px 14px 28px',
        cursor: 'pointer',
    },
    paymentModeCenterRowContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    paymentModeTextContainer: {
        width: '100%',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    highlightedSelector: {
        border: `3px ${colors.yellow} solid`,
        boxShadow: '3px 3px 3px 2px rgba(0, 0, 0, 0.1), inset 0 0 5px 1px rgba(0, 0, 0, 0.1)',
    },
    selectorImage: {
        paddingLeft: '30px',
    },
    selectorTypography: {
        fontWeight: 700,
        textAlign: 'left',
        paddingLeft: '27px',
        '@media only screen and (min-width: 960px) and (max-width:1024px)': {
            paddingRight: '8px',
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0px',
            textAlign: 'center',
        },
    },
    ahora6Container: {
        display: 'flex',
        flexDirection: 'row',
        '@media (max-width: 1040px)': {
            display: 'none',
        },
        paddingRight: '35px',
        '@media (max-width: 1100px)': {
            paddingRight: '12px',
        },
    },
    stepChangeButton: {
        width: 129,
        height: 34,
        margin: GDTheme().spacing(2),
        marginTop: 0,
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
}));

const SelectPayment = ({
    loading,
    goToPreviousStep,
    goToConfirmPaymentSubStep,
    selectedPaymentMode,
    setSelectedPaymentMode,
    loadingTotalPrice,
    shippingCost,
}) => {
    const classes = useStyles();
    const [state, dispatch] = useContext(UserContext);

    return (
        <>
            <Box className={`${classes.paymentModeWrapper}`}>

                <Box
                    mb={2}
                    className={
                        `
                            ${classes.paymentModeSelector}
                            ${(selectedPaymentMode
                            === paymentHelper.paymentModeConstants.BANK_TRANSFER) && classes.highlightedSelector}
                        `
                    }
                    onClick={() => {
                        if (!loadingTotalPrice) {
                            setSelectedPaymentMode(paymentHelper
                                .paymentModeConstants.BANK_TRANSFER);
                        }
                    }}
                >
                    <Box className={classes.paymentModeCenterRowContainer}>
                        {selectedPaymentMode
                            === paymentHelper.paymentModeConstants.BANK_TRANSFER
                            ? <RadioButtonCheckedIcon />
                            : <RadioButtonUncheckedIcon />}
                        <img src={BankTransferImage} alt="Transferencias" className={classes.selectorImage} />
                    </Box>
                    <Box className={`${classes.paymentModeCenterRowContainer} ${classes.paymentModeTextContainer}`}>
                        <Typography variant="subtitle1" className={classes.selectorTypography}>
                            Transferencia
                        </Typography>
                        <Typography variant="h6" style={{ color: colors.blackGrey }}>
                            {cartHelper.getFormattedTotalPrice(
                                state.user?.cart?.items,
                                paymentHelper.paymentModeConstants.BANK_TRANSFER,
                                isSubsidizedShippingPrice ? 0 : shippingCost,
                                state.user?.cart.appliedCoupon?.discount_percentage,
                            )}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box className={`${classes.paymentModeWrapper}`}>
                <Box
                    mb={2}
                    className={
                        `
                            ${classes.paymentModeSelector}
                            ${(selectedPaymentMode
                            === paymentHelper.paymentModeConstants.DEBIT_CARD_OR_ONE_INSTALLMENT) && classes.highlightedSelector}
                        `
                    }
                    onClick={() => {
                        if (!loadingTotalPrice) {
                            setSelectedPaymentMode(
                                paymentHelper
                                    .paymentModeConstants.DEBIT_CARD_OR_ONE_INSTALLMENT,
                            );
                        }
                    }}
                >
                    <Box className={classes.paymentModeCenterRowContainer}>
                        {selectedPaymentMode
                            === paymentHelper.paymentModeConstants.DEBIT_CARD_OR_ONE_INSTALLMENT
                            ? <RadioButtonCheckedIcon />
                            : <RadioButtonUncheckedIcon />}
                        <img src={DebitCardOr1InstallmentImage} alt="Tarjeta Débito" className={classes.selectorImage} />
                    </Box>
                    <Box className={`${classes.paymentModeCenterRowContainer} ${classes.paymentModeTextContainer}`}>
                        <Typography variant="subtitle1" className={classes.selectorTypography}>
                            Tarjeta de Crédito 1 pago/Débito
                        </Typography>
                        <Typography variant="h6" style={{ color: colors.blackGrey }}>
                            {cartHelper.getFormattedTotalPrice(
                                state.user?.cart?.items,
                                paymentHelper.paymentModeConstants.DEBIT_CARD_OR_ONE_INSTALLMENT,
                                isSubsidizedShippingPrice ? 0 : shippingCost,
                                state.user?.cart.appliedCoupon?.discount_percentage,
                            )}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box className={`${classes.paymentModeWrapper}`}>
                <Box
                    mb={2}
                    className={
                        `
                            ${classes.paymentModeSelector}
                            ${(selectedPaymentMode
                            === paymentHelper.paymentModeConstants.CARD) && classes.highlightedSelector}
                        `
                    }
                    onClick={() => {
                        if (!loadingTotalPrice) {
                            setSelectedPaymentMode(paymentHelper
                                .paymentModeConstants.CARD);
                        }
                    }}
                >
                    <Box className={classes.paymentModeCenterRowContainer}>
                        {selectedPaymentMode
                            === paymentHelper.paymentModeConstants.CARD
                            ? <RadioButtonCheckedIcon />
                            : <RadioButtonUncheckedIcon />}
                        <img src={CardImage} alt="Tarjetas" className={classes.selectorImage} />
                    </Box>
                    <Box className={`${classes.paymentModeCenterRowContainer} ${classes.paymentModeTextContainer}`}>
                        <Typography variant="subtitle1" className={classes.selectorTypography}>
                            Tarjeta de Crédito en cuotas
                        </Typography>
                        <Box className={classes.paymentModeCenterRowContainer}>
                            <Box className={classes.ahora6Container}>
                                <img
                                    alt="Ahora 6"
                                    height={48}
                                    src={Ahora6Icon}
                                    width={32}
                                />
                            </Box>
                            <Typography variant="h6" style={{ color: colors.blackGrey }}>
                                {cartHelper.getFormattedTotalPrice(
                                    state.user?.cart?.items,
                                    paymentHelper.paymentModeConstants.CARD,
                                    isSubsidizedShippingPrice ? 0 : shippingCost,
                                    state.user?.cart.appliedCoupon?.discount_percentage,
                                )}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box className={classes.actionButtons} flexGrow={1} m={2}>
                <Button
                    variant="outlined"
                    onClick={async () => {
                        await setSelectedPaymentMode(null);
                        goToPreviousStep();
                    }}
                    className={classes.stepChangeButton}
                    disabled={loadingTotalPrice}
                >
                    Anterior
                </Button>
                <Button
                    loading={loading}
                    variant="contained"
                    onClick={goToConfirmPaymentSubStep}
                    className={classes.stepChangeButton}
                    disabled={loadingTotalPrice || (selectedPaymentMode === null)}
                >
                    Siguiente
                </Button>
            </Box>
        </>
    );
};

export default SelectPayment;
