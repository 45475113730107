import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CircularProgress from '@material-ui/core/CircularProgress';

import { UserContext } from '../../../contexts';

import CouponCode from '../../components/CouponCode';
import Total from '../../components/Total';
import TotalWithCoupon from '../../components/TotalWithCoupon';
import { isSubsidizedShippingPrice } from '../../../data/constants';

import cartHelper from '../../../helpers/cartHelper';
import paymentHelper from '../../../helpers/paymentHelper';
import proBuildHelper from '../../../helpers/proBuildHelper';

import colors from '../../../theme/colors';

const useStyles = makeStyles((theme) => ({
    resume: {
        background: colors.grey,
        color: colors.black,
        minWidth: 300,
        paddingBottom: '20px',
        paddingLeft: '30px',
        paddingRight: '18px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 40,
        },
    },
    freeShippingContainer: {
        display: 'flex',
        background: colors.grey,
        color: colors.black,
        padding: '20px 18px 20px 30px',
        marginTop: '32px',
    },
    freeShippingText: {
        width: '184px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '37px',
        fontWeight: 700,
        marginTop: '5px',
    },
    freeShippingTextsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    products: {
        fontWeight: 300,
    },
    details: {
        paddingTop: '0px !important',
    },
    itemDescription: {
        fontWeight: 400,
    },
    itemPrice: {
        fontWeight: 700,
    },
    itemQuantity: {
        fontWeight: 600,
        marginLeft: 5,
    },
    itemPhoto: {
        height: 59,
        width: 58,
    },
    totalDivider: { backgroundColor: '#1C1C1C' },
    detailsButton: {
        paddingLeft: 0,
        paddingRight: 0,
    },
}));

const OrderSummary = ({
    shippingData, actualStep, loadingShippingPrice, loadingTotalPrice, selectedPaymentMode,
    orderSummaryRef,
}) => {
    const classes = useStyles();
    const [showDetails, setShowDetails] = useState(false);
    const [state, dispatch] = useContext(UserContext);
    const products = proBuildHelper.formatProductsWithBuilds(state.user?.cart?.items || []);
    const handleShowDetails = (e) => {
        e.preventDefault();
        setShowDetails(!showDetails);
    };

    const calculateShippingPrice = () => (
        isSubsidizedShippingPrice ? 0 : shippingData.price || 0
    );

    const calculateTotalPrice = (price) => (
        (price || 0) + calculateShippingPrice()
    );

    const calculateDiscountedPrice = (price) => (
        (((price || 0))
        * state.user?.cart?.appliedCoupon?.discount_percentage)
        / 100
    );

    const formatDisplayedPrice = (price = 0) => cartHelper.formatPrice(
        calculateTotalPrice(price),
    );

    const formatDisplayedDiscountedPrice = (price = 0) => cartHelper.formatPrice(
        calculateDiscountedPrice(price),
    );

    const formatDisplayedPriceWithDiscount = (price = 0) => cartHelper.formatPrice(
        calculateTotalPrice(price) - calculateDiscountedPrice(price),
    );

    return (
        <>
            <Box className={classes.resume} py={2} ref={orderSummaryRef}>
                <Typography variant="h6">
                    Resumen de tu compra
                </Typography>
                <Box mt={2} mb={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={10}>
                            <Typography variant="h6" className={classes.products}>
                                Productos
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="h6" className={classes.products}>
                                {cartHelper.calculateTotalItems(state.user?.cart?.items || [])}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.details} xs={12}>
                            <Box display="flex">
                                <Button
                                    className={classes.detailsButton}
                                    onClick={handleShowDetails}
                                    endIcon={showDetails ? (
                                        <ArrowDropUp />
                                    ) : <ArrowDropDown />}
                                >
                                    VER DETALLE
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                {showDetails && (
                    <Box mb={2}>
                        {products.map((item, i) => (
                            <Box mb={3} key={item.id}>
                                <Box mb={3}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={4}>
                                            <img src={item.image} alt="error" className={classes.itemPhoto} />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography className={classes.itemDescription} variant="subtitle2">
                                                {item.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="flex-end" alignItems="center">
                                                <Typography className={classes.itemPrice} variant="h6">
                                                    {`${selectedPaymentMode
                                                    === paymentHelper.paymentModeConstants.CARD
                                                        ? cartHelper.formatPrice(
                                                            item.price.list,
                                                        )
                                                        : cartHelper.formatPrice(
                                                            item.price.special,
                                                        )}`}
                                                </Typography>
                                                <Typography className={classes.itemQuantity} variant="subtitle1">
                                                    {` (x${item.quantity})`}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                {state.user.cart.items.length !== i + 1 && (
                                    <Box>
                                        <Divider />
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </Box>
                )}

                {!isSubsidizedShippingPrice && (
                    <>
                        <Divider className={classes.totalDivider} />
                        {loadingShippingPrice
                            ? (
                                <Box mt={2} mb={2}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}>
                                            <Typography variant="h6" className={classes.products}>
                                                Envío
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <CircularProgress
                                                    size={18}
                                                    color="#000"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )
                            : (
                                <>
                                    {shippingData.price > 0 && actualStep > 1
                        && (
                            <Box mt={2} mb={2}>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" className={classes.products}>
                                            Envío
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <Typography variant="h6">
                                                {cartHelper.formatPrice(shippingData.price)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                                </>
                            )}
                    </>
                )}

                <Divider className={classes.totalDivider} />
                <Box mt={1} mb={2}>
                    {state.user?.cart?.appliedCoupon
                        ? (
                            <TotalWithCoupon
                                actualStep={actualStep}
                                loadingTotalPrice={loadingTotalPrice}
                                selectedPaymentMode={selectedPaymentMode}
                                formatDisplayedPrice={formatDisplayedPrice}
                                formatDisplayedDiscountedPrice={formatDisplayedDiscountedPrice}
                                formatDisplayedPriceWithDiscount={formatDisplayedPriceWithDiscount}
                            />
                        )
                        : (
                            <Total
                                actualStep={actualStep}
                                loadingTotalPrice={loadingTotalPrice}
                                selectedPaymentMode={selectedPaymentMode}
                                formatDisplayedPrice={formatDisplayedPrice}
                            />
                        )}
                </Box>

                <CouponCode />
            </Box>
            {isSubsidizedShippingPrice && (
                <Grid container className={classes.freeShippingContainer}>
                    <Grid item xs={3}>
                        {' '}
                        <LocalShippingIcon style={{ fontSize: '44px' }} />
                    </Grid>
                    <Grid item xs={9} className={classes.freeShippingTextsContainer}>
                        {' '}
                        <Box className={classes.freeShippingText}>
                            <Typography variant="h4" style={{ color: colors.green }}>
                                ENVÍO GRATIS
                            </Typography>
                        </Box>
                        <Typography variant="h5" style={{ fontWeight: 300 }}>
                            Por
                            {' '}
                            <Typography variant="h5" style={{ fontWeight: 400, display: 'inline' }}>Via Cargo</Typography>
                        </Typography>

                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default OrderSummary;
