/* eslint-disable array-callback-return */
import _ from 'lodash';
import { snackActions } from '../SnackBarUtils';
import paymentHelper from './paymentHelper';

const formatterPesos = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
});

const formatPrice = (price) => formatterPesos.format(price).replace(/\D00$/, '').replace(/\s/g, '');

const getTotalPrice = (items, typeOfPayment, shippingCost = 0, discount = null) => {
    let typeOfPrice = 'special';
    if (typeOfPayment === paymentHelper.paymentModeConstants.CARD) typeOfPrice = 'list';
    const totalPrice = items
        .reduce((acc, current) => acc + current.price[typeOfPrice] * current.quantity, 0);
    if (discount) {
        const totalPriceWithDiscount = totalPrice - ((totalPrice * discount) / 100);
        return totalPriceWithDiscount + shippingCost;
    }
    return totalPrice + shippingCost;
};

const getFormattedTotalPrice = (items, typeOfPayment, shippingCost = 0, discount) => {
    const totalPrice = getTotalPrice(items, typeOfPayment, shippingCost, discount);
    return formatPrice(totalPrice.toString());
};

function calculateTotalItemsPrice(cartItems) {
    const totalItemsPrice = cartItems.map(
        (item) => (item.quantity || 0) * (item.price || 0),
    ).reduce((a, b) => a + b, 0);
    return formatPrice(totalItemsPrice);
}

function calculateTotalItems(cartItems = []) {
    const allBuildIds = [];
    const products = [];
    cartItems.forEach((item) => {
        if (item.buildId && item.type !== 'operative_system') {
            allBuildIds.push(item.buildId);
        }
        if (!item.buildId) {
            products.push(item.id);
        }
    });
    const uniqueIds = [...new Set(allBuildIds)];
    return products.length + uniqueIds.length;
}

const toggleOperativeSystemFrom = ({ cartItems, buildId, operativeSystem }) => {
    const newCartItems = [...cartItems];
    const soIndex = newCartItems.findIndex((cartItem) => (
        cartItem.buildId === buildId && cartItem.type === 'operative_system'
    ));
    if (soIndex === -1) {
        const so = operativeSystem;
        const soAsProduct = {
            id: so.id,
            quantity: 1,
            price: {
                special: so.price.special.ARS,
            },
            name: so.name,
            code: so.code,
            buildId,
            image: so.images[0],
            type: so.type,
        };
        newCartItems.push(soAsProduct);
        return newCartItems;
    }
    newCartItems.splice(soIndex, 1);
    return newCartItems;
};

const parseProductsToSend = (cartItems) => cartItems.map(
    ({
        id, quantity, buildId = null, buildSubItemIndex = null,
    }) => ({
        id, quantity, buildId, buildSubItemIndex,
    }),
);

const checkPriceChange = ({ cartItems, newCartItems }) => _.differenceBy(cartItems, newCartItems, 'price.special');

const warnIfPriceHasChanged = async (newCartItems, oldCartItems = null) => {
    const user = await JSON.parse(localStorage.getItem('user'));
    if (user) {
        const productsWithNewPrice = checkPriceChange(
            {
                cartItems: oldCartItems ?? user.cart?.items ?? [],
                newCartItems,
            },
        );
        if (productsWithNewPrice
            && productsWithNewPrice.length !== 0
            && productsWithNewPrice.length > 1) {
            snackActions.warning('Hubo un cambio en el precio de algunos productos del carrito. Por favor validalos entrando al carrito.');
        }
        if (productsWithNewPrice && productsWithNewPrice.length === 1) {
            snackActions.warning(`Hubo un cambio en el precio de: "${productsWithNewPrice[0].name}". Por favor validalo entrando al carrito.`);
        }
    }
};

function getProvinces() {
    const provinces = [
        { name: 'Buenos Aires', id: 'Buenos Aires' },
        { name: 'Capital Federal', id: 'Capital Federal' },
        { name: 'Catamarca', id: 'Catamarca' },
        { name: 'Chaco', id: 'Chaco' },
        { name: 'Chubut', id: 'Chubut' },
        { name: 'Córdoba', id: 'Cordoba' },
        { name: 'Corrientes', id: 'Corrientes' },
        { name: 'Entre Ríos', id: 'Entre Rios' },
        { name: 'Formosa', id: 'Formosa' },
        { name: 'Jujuy', id: 'Jujuy' },
        { name: 'La Pampa', id: 'La Pampa' },
        { name: 'La Rioja', id: 'La Rioja' },
        { name: 'Mendoza', id: 'Mendoza' },
        { name: 'Misiones', id: 'Misiones' },
        { name: 'Neuquén', id: 'Neuquen' },
        { name: 'Río Negro', id: 'Rio Negro' },
        { name: 'Salta', id: 'Salta' },
        { name: 'San Juan', id: 'San Juan' },
        { name: 'San Luis', id: 'San Luis' },
        { name: 'Santa Cruz', id: 'Santa Cruz' },
        { name: 'Santa Fe', id: 'Santa Fe' },
        { name: 'Santiago del Estero', id: 'Santiago del Estero' },
        { name: 'Tierra del Fuego', id: 'Tierra del Fuego' },
        { name: 'Tucumán', id: 'Tucuman' },
    ];
    return provinces;
}

function createBuildObject(product, type, quantity = 1) {
    const buildImage = (product.images
        && product.images[0]) ? product.images[0].url : '';

    const cleanDescription = product.description.substring(
        product.description.lastIndexOf('<p>') + 3,
        product.description.lastIndexOf('</p>'),
    );
    const buildToAdd = {
        id: product.id,
        type,
        price: product.price.special.ARS,
        withLicense: true,
        code: product.code,
        name: product.name,
        description: cleanDescription,
        image: buildImage,
        quantity,
    };

    return buildToAdd;
}

const formatCartItemForGAEvent = (cartItem = {}, useSpecialPrice) => {
    const {
        id, name, type, price, quantity,
    } = cartItem;
    return ({
        item_id: id,
        item_name: name,
        item_category: type,
        price: useSpecialPrice ? price.special : price.list,
        quantity,
        index: 0,
    });
};

const formatCartItemsForGAEvent = (cartItems = [], useSpecialPrice) => cartItems
    .map((item, index) => ({ ...formatCartItemForGAEvent(item, useSpecialPrice), index }));

const cartHelper = {
    getTotalPrice,
    getFormattedTotalPrice,
    formatPrice,
    getProvinces,
    calculateTotalItemsPrice,
    calculateTotalItems,
    createBuildObject,
    toggleOperativeSystemFrom,
    parseProductsToSend,
    warnIfPriceHasChanged,
    formatCartItemForGAEvent,
    formatCartItemsForGAEvent,
};

export default cartHelper;
