import React, { useContext } from 'react';
import {
    Box, Grid, Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UserContext } from '../../contexts';
import paymentHelper from '../../helpers/paymentHelper';

const Total = ({
    actualStep,
    loadingTotalPrice,
    selectedPaymentMode,
    formatDisplayedPrice,
}) => {
    const [state] = useContext(UserContext);

    return (
        <Grid container spacing={3} style={{ marginTop: '8px' }}>
            <Grid item xs={4}>
                <Typography variant="h6">
                    Total
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Box display="flex" justifyContent="flex-end">
                    {loadingTotalPrice
                        ? <CircularProgress color="#000" />
                        : (
                            <Typography variant="h3">
                                {actualStep > 1
                                    ? (
                                        <>
                                            {selectedPaymentMode
                                                === paymentHelper.paymentModeConstants.CARD
                                                ? formatDisplayedPrice(
                                                    state.user?.cart?.totalPrice?.list,
                                                )
                                                : formatDisplayedPrice(
                                                    state.user?.cart?.totalPrice?.special,
                                                )}
                                        </>
                                    )
                                    : formatDisplayedPrice(
                                        (state.user?.cart?.totalPrice?.special || 0),
                                    )}
                            </Typography>
                        )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default Total;
