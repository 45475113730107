import userConstants from './userConstants';
import storageHelper from '../../helpers/storageHelper';

const user = JSON.parse(localStorage.getItem('user'));
export const userInitialState = user ? { user } : { user: {} };

export const userReducer = (state = userInitialState, action) => {
    const userUpdated = state.user ?? [];
    let newUser = [];
    switch (action.type) {
    case userConstants.SET_USER:
        newUser = action.user;
        newUser.cart = state.user.cart;

        return {
            ...state,
            user: newUser,
        };
    case userConstants.LOGOUT:
        storageHelper.clearStorage(); // FIXME: Eliminar todos los efectos de lado de los reducers
        return {
            ...state,
            user: { },
        };
    case userConstants.UPDATE_CART:
        userUpdated.cart = action.cart;
        storageHelper.setUserCart(action.cart);
        return {
            ...state,
            user: userUpdated,
        };
    case userConstants.UPDATE_COUPON:
        userUpdated.cart.appliedCoupon = action?.appliedCoupon;
        storageHelper.setUserCart(userUpdated.cart);
        return {
            ...state,
            user: userUpdated,
        };
    case userConstants.DELETE_CART:
        delete userUpdated.cart;
        storageHelper.deleteUserCart(action.cart);
        return {
            ...state,
            user: userUpdated,
        };
    case userConstants.UPDATE_PROFILE_PICTURE:
        userUpdated.profileImageURL = action.profileImageURL;
        storageHelper.setUser(userUpdated);
        return {
            ...state,
            user: userUpdated,
        };
    default:
        return state;
    }
};
