import React, { useContext, useEffect } from 'react';
import {
    Box, Grid,
} from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import API from '../../api/API';
import userConstants from '../../contexts/User/userConstants';
import { UserContext } from '../../contexts';
import CartLinkedProductCard from './CartLinkedProductCard';
import ProductsSkeleton from '../product/ProductsSkeleton';
import NoResultsMessage from '../components/NoResultsMessage';

const ProductsList = ({
    products, totalPages, currentPage, getProducts, isLoading,
}) => {
    const [userState, dispatchUser] = useContext(UserContext);

    useEffect(() => {
        const { cart } = userState.user;
        if (!cart) {
            const createCart = async () => {
                API.carts.post().then((response) => {
                    dispatchUser({
                        type: userConstants.UPDATE_CART,
                        cart: response.data,
                    });
                });
            };
            createCart();
        }
    }, [dispatchUser, userState]);

    return (
        <Box mt={0} mb={2}>
            {products?.length > 0 && (
                <InfiniteScroll
                    dataLength={products?.length}
                    next={getProducts}
                    hasMore={currentPage < totalPages}
                    style={{ overflow: 'inherit' }}
                    scrollThreshold="200px"
                >
                    <Grid container spacing={2}>
                        {products?.map((product) => (
                            <Grid key={product.id} item xs={6} sm={4} md={4} lg={3} xl={2}>
                                <CartLinkedProductCard product={product} multiSelect />
                            </Grid>
                        ))}
                    </Grid>
                </InfiniteScroll>
            )}
            {isLoading && <ProductsSkeleton showSortSkeleton={false} marginLeft={0} />}
            {!isLoading && products?.length === 0 && (
                <Box display="flex" alignItems="center" justifyContent="center" width="100%" minHeight="80vh">
                    <NoResultsMessage from="ecommerce" />
                </Box>
            )}
        </Box>
    );
};

export default ProductsList;
