import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box, Button, Divider, FormControl, Grid, Typography,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import colors from '../../../theme/colors';
import cartHelper from '../../../helpers/cartHelper';
import { UserContext } from '../../../contexts';
import userConstants from '../../../contexts/User/userConstants';
import handler from '../../../helpers/handler';
import API from '../../../api/API';
import CouponCode from '../../components/CouponCode';
import Total from '../../components/Total';
import TotalWithCoupon from '../../components/TotalWithCoupon';
import shippingDataHelper from '../../../helpers/shippingDataHelper';

const useStyles = makeStyles((theme) => ({
    resume: {
        background: colors.grey,
        color: colors.black,
        minWidth: 300,
    },
    products: {
        fontWeight: 300,
    },
    disabledButton: {
        backgroundColor: '#DBDBD5 !important',
        color: '#1C1C1C !important',
    },
}));

const Resume = ({ clearCart }) => {
    const classes = useStyles();
    const history = useHistory();
    const [state, dispatch] = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();

    const handleClick = () => {
        if (state.user?.role) {
            API.users.me()
                .catch(() => {
                    handler.logout(dispatch);
                    enqueueSnackbar('Su sesión ha caducado. Vuelva a iniciar sesión.', { variant: 'warning' });
                });
        }
    };

    useEffect(() => {
        if (!state.user.cart) {
            API.carts.post()
                .then((response) => {
                    dispatch({ type: userConstants.UPDATE_CART, cart: response.data });
                })
                .catch((error) => {
                    handler.handleError({ error, userContextDispatch: dispatch, enqueueSnackbar });
                });
        }
    }, [state, dispatch, enqueueSnackbar]);

    const calculateTotalPrice = (price) => (price || 0);

    const calculateDiscountedPrice = (price) => (
        ((price || 0)
        * state.user?.cart?.appliedCoupon?.discount_percentage)
        / 100
    );

    const formatDisplayedPrice = (price = 0) => cartHelper.formatPrice(
        calculateTotalPrice(price),
    );

    const formatDisplayedDiscountedPrice = (price = 0) => cartHelper.formatPrice(
        calculateDiscountedPrice(price),
    );

    const formatDisplayedPriceWithDiscount = (price = 0) => cartHelper.formatPrice(
        calculateTotalPrice(price) - calculateDiscountedPrice(price),
    );

    return (
        <Box className={classes.resume} px={4} py={2}>
            <Box mb={4}>
                <Typography variant="h6">
                    Resumen de tu compra
                </Typography>
            </Box>
            <Box mt={4} mb={2}>
                <Grid container spacing={3}>
                    <Grid item xs={10}>
                        <Typography variant="h6" className={classes.products}>
                            Productos
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="h6" className={classes.products}>
                            {cartHelper.calculateTotalItems(state.user?.cart?.items || [])}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Divider />
            <Box mt={1} mb={2}>
                {state.user?.cart?.appliedCoupon
                    ? (
                        <TotalWithCoupon
                            formatDisplayedPrice={formatDisplayedPrice}
                            formatDisplayedDiscountedPrice={formatDisplayedDiscountedPrice}
                            formatDisplayedPriceWithDiscount={formatDisplayedPriceWithDiscount}
                        />
                    )
                    : (
                        <Total
                            formatDisplayedPrice={formatDisplayedPrice}
                        />
                    )}
            </Box>

            <Box mt={4} mb={3}>
                <FormControl fullWidth>
                    <Button
                        variant="contained"
                        component={Link}
                        to="/completeOrder"
                        classes={{ disabled: classes.disabledButton }}
                        disabled={state.user?.cart?.items?.length === 0}
                        onClick={handleClick}
                    >
                        Finalizar compra
                    </Button>
                </FormControl>
            </Box>
            <Box mb={2}>
                <FormControl fullWidth>
                    <Button
                        variant="outlined"
                        type="submit"
                        onClick={() => history.push('/productos')}
                    >
                        Agregar más productos
                    </Button>
                </FormControl>
            </Box>
            <Box mb={3}>
                <FormControl fullWidth>
                    <Button
                        style={{
                            border: 'none',
                            outline: 'none',
                        }}
                        onClick={clearCart}
                    >
                        Limpiar carrito
                    </Button>
                </FormControl>
            </Box>
            <CouponCode />
        </Box>
    );
};

export default Resume;
