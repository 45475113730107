import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Link,
    Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import OrderDetails from '../../../user/OrderDetails';

const useStyles = makeStyles(() => ({
    actionButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
}));

const OrderConfirmation = ({ order }) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <div>
            <Box mt={2} mb={6}>
                <Typography variant="h4">
                    Compra finalizada
                </Typography>
                <Box mt={1}>
                    <Typography variant="subtitle1">
                        ¡Gracias por elegir Gamer Factory!
                        Todos los datos de tu compra se enviarán por mail.
                    </Typography>
                </Box>
                <Typography variant="subtitle1">
                    Podés hacer el
                    <Link href="/">
                        {' seguimiento del envío '}
                    </Link>
                    desde tu perfil.
                </Typography>
            </Box>
            <OrderDetails order={order} showTitleOnDesktop />
            <Box className={classes.actionButtons} flexGrow={1} m={2}>
                <Button
                    onClick={() => history.push('/account')}
                    variant="contained"
                >
                    Ir a mis compras
                </Button>
            </Box>

        </div>
    );
};

export default OrderConfirmation;
