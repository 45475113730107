import React, { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    CircularProgress,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import { UserContext } from '../../contexts';
import userConstants from '../../contexts/User/userConstants';

import colors from '../../theme/colors';

import API from '../../api/API';

const useStyles = makeStyles(() => ({
    couponCodeInputContainer: {
        display: 'flex',
        maxHeight: '38px',
        width: '100%',
    },
    couponCodeInputTextfield: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            height: '38px',
        },
        '& .MuiInputLabel-outlined[data-shrink=false]': {
            transform: 'translate(12px, 12px) scale(1)',
        },
    },
    couponCodeInputIconButton: {
        background: colors.black,
        width: '38px',
        height: '38px',
        padding: '0px',
        borderRadius: 0,
    },
    couponCodeInputIcon: {
        color: colors.yellow,
        padding: 0,
    },
    couponCodeContainer: {
        alignItems: 'center',
        background: colors.lightGrey,
        display: 'flex',
        justifyContent: 'center',
        padding: '12px 25px 9px',
    },
    couponClickHereButton: {
        fontWeight: 700,
        textDecoration: 'underline',
        padding: 0,
        cursor: 'pointer',
    },
}));

const CouponCode = () => {
    const classes = useStyles();
    const [isCouponCodeInputOpen, setIsCouponCodeInputOpen] = useState(false);
    const [state, dispatch] = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [coupon, setCoupon] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const handleCouponCodeChange = (e) => setCoupon(e.target.value);

    const handleCouponCodeButtonClick = async () => {
        if (state.user?.cart?.appliedCoupon?.code === coupon) {
            enqueueSnackbar('El código ingresado es el ya existente.', { variant: 'error' });
        } else {
            setIsLoading(true);
            await API.carts.applyCoupon({ coupon, id: state.user.cart.id })
                .then((response) => {
                    dispatch({
                        type: userConstants.UPDATE_COUPON,
                        appliedCoupon: response.data.appliedCoupon,
                    });
                    setIsCouponCodeInputOpen(false);
                })
                .catch((error) => {
                    if (error.response?.status === 404) enqueueSnackbar('El código ingresado es inválido.', { variant: 'warning' });
                    else enqueueSnackbar('Hubo un problema al validar el código. Intenta nuevamente en unos minutos.', { variant: 'error' });
                })
                .finally(() => setIsLoading(false));
        }
    };

    const onKeyDownCouponCodeInput = (e) => {
        if (e.keyCode === 13) handleCouponCodeButtonClick();
    };

    return (
        <>
            {isCouponCodeInputOpen && (
                <Box className={classes.couponCodeInputContainer}>
                    <TextField
                        id="code"
                        label="Introducí tu código"
                        variant="outlined"
                        fullWidth
                        onChange={handleCouponCodeChange}
                        className={classes.couponCodeInputTextfield}
                        inputProps={{
                            style: {
                                padding: 10.5,
                            },
                        }}
                        onKeyDown={onKeyDownCouponCodeInput}
                    />

                    <IconButton
                        type="button"
                        className={classes.couponCodeInputIconButton}
                        onClick={handleCouponCodeButtonClick}
                    >
                        {isLoading
                            ? <CircularProgress size={20} />
                            : <PlayArrowIcon className={classes.couponCodeInputIcon} />}
                    </IconButton>
                </Box>
            )}

            {!isCouponCodeInputOpen && !state.user?.cart?.appliedCoupon && (
                <Box className={classes.couponCodeContainer}>
                    <Typography display="inline" variant="subtitle1">
                        ¿Tenés un cupón?&nbsp;
                    </Typography>
                    <Typography
                        display="inline"
                        variant="subtitle1"
                        className={classes.couponClickHereButton}
                        onClick={() => setIsCouponCodeInputOpen(true)}
                    >
                        Hacé click acá
                    </Typography>
                </Box>
            )}
        </>
    );
};
export default CouponCode;
